import React, { lazy, Suspense } from 'react';

import { AppPlugin } from '@grafana/data';
import { LoadingPlaceholder } from '@grafana/ui';

import type { OverviewExtensionProps } from 'modules/extensions/overview/makeOverviewExtensionScene';

let initialized = false;

async function init() {
  if (initialized) {
    return;
  }
  initialized = true;
  const { registerPalette } = await import('utils/colors');
  const { initializeFaro } = await import('./faro/initialize');

  initializeFaro();
  registerPalette();
}

const LazyOverviewExtensionScene = lazy(async () => {
  await init();
  return import('modules/extensions/overview/makeOverviewExtensionScene');
});

const OverviewExtensionScene = (props: OverviewExtensionProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyOverviewExtensionScene {...props} />
  </Suspense>
);

const Root = lazy(async () => {
  await init();
  return await import('./Root');
});

export const plugin = new AppPlugin<object>().setRootPage(Root);

if (plugin.exposeComponent) {
  plugin.exposeComponent({
    title: 'Overview',
    description: 'Overview of a service',
    id: 'grafana-app-observability-app/overview-extension/v1',
    component: OverviewExtensionScene,
  });
}
